<template>
    <div ref="main-container">
        <div>
            <b-row class="mb-2">
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <h1 class="mb-0">{{i18nT(`Locataires`)}}</h1>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-form-group
                    >
                        <b-form-radio-group
                            v-model="listMode"
                            button-variant="outline-secondary"
                            name="radio-btn-outline"
                            buttons
                            class="ren-active-buttons"
                        >
                            <b-form-radio value="active"  >
                                {{ i18nT(`Actifs`)}}
                                <b-badge :variant="listMode == 'active' ? 'light-primary' : 'light-secondary' " class="ml-50 text-primary">{{activeTenantsCount}}</b-badge>
                            </b-form-radio>
                            <b-form-radio value="archive" >{{i18nT(`Archives`)}}
                                <b-badge class="ml-50 text-primary" :variant="listMode == 'archive' ? 'light-primary' : 'light-secondary'">{{archiveTenantsCount}}</b-badge>
                            </b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col
                    cols="12"
                    md="4"
                    sm="12"
                >
                    <b-dropdown
                        variant="primary"
                        class="float-right pl-1 pb-1"
                    >
                        <template #button-content>
                            <RtIcon variant="light" icon="plus-circle mr-50" />
                            {{ i18nT(`Nouveau locataire`) }}
                        </template>

                        <b-dropdown-item class="text-primary"
                                         :to="{ name: 'create-tenant', params:{tab:'general-info'} }"
                        >
                            <div class=''>
                                <RtIcon variant="light" icon="plus-circle mr-50" />
                                {{ i18nT(`Nouveau locataire`) }}
                            </div>
                        </b-dropdown-item>
                        <b-dropdown-item
                            :to="{name: 'import-tenants'}"
                        >
                            <div>
                                <RtIcon variant="light" icon="file-import mr-50" />
                                {{ i18nT(`Importer`) }}
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
            </b-row>
        </div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                      <b-col
                          cols="12"
                          md="4"
                      >
                          <b-form-group :label="i18nT(`Type`)">
                              <rt-select
                                v-model="tenantType"
                                :options="tenantTypes"
                                label="text"
                                :selectable="selected => selected.disabled !== true"
                                :clearable="false"
                                :placeholder="i18nT(`Tous les types`)"
                                :reduce="(e) => e.value"
                                id="tenantType"
                            />
                          </b-form-group>
                      </b-col>
                    <b-col
                        cols="12"
                        md="4"
                    >
                      <b-form-group :label="i18nT(`Propriétaires`)">
                            <rt-select
                                v-model="tenantLandlord"
                                :options="tenantLandlords"
                                label="text"
                                :selectable="selected => selected.disabled !== true"
                                :placeholder="i18nT(`Tous les propriétaires`)"
                                :closeOnSelect="false"
                                multiple
                                deselectFromDropdown
                                :persistentPlaceholder="['Propriétaire', 'Propriétaires']"
                                :reduce="(e) => e.value"
                                id='tenantLandlord'
                                icon="user-check"
                            />
                      </b-form-group>
                    </b-col>
                    <b-col
                        cols="12"
                        md="4"

                    >
                        <b-form-group :label="i18nT(`Bien`)">

                            <rt-select
                                v-model="tenantProperty"
                                :options="tenantProperties"
                                label="text"
                                :selectable="selected => selected.disabled !== true"
                                :placeholder="i18nT(`Tous les biens`)"
                                :closeOnSelect="false"
                                multiple
                                deselectFromDropdown
                                :persistentPlaceholder="['Bien', 'Biens']"
                                :reduce="(e) => e.value"
                                id='tenantProperty'
                                icon="house"
                            />
                      </b-form-group>
                    </b-col>
                    <b-col
                            cols="12"
                            sm="3"
                            v-if="false"
                            class="d-flex align-items-center"
                        >
                            <b-button
                                class="mt-1"
                                variant="outline-danger"
                                size="md"
                                @click="clearFilters"
                                v-b-tooltip.hover.top="i18nT(`Supprimer les filtres`)"
                            >
                                <feather-icon icon="XCircleIcon"/>
                              <!-- {{ i18nT(`Supprimer les filtres`) }} -->
                            </b-button>
                              <!-- <feather-icon class="text-danger" size="2x" icon="XCircleIcon"/> -->
                        </b-col>
                        <div class="ml-50">
                            <rt-all-filters @removeFilter="removeFilter" @clearFilters="clearFilters"/>
                        </div>
                  </b-row>
                    
                </div>
            </div>
        </b-card>
            <b-card no-body>
                <div>
                      <div class="row mx-2 mt-2">
                          <div class="col-12 col-md-6 col-sm-6 mb-1 pl-0">
                              <v-select
                                  v-model="perPage"
                                  :clearable="false"
                                  :options="perPageOptions"
                                  class="per-page-selector d-inline-block"
                              />
                              <b-dropdown
                                  v-if="selectedItems.length > 0"
                                  class="d-inline ml-1"
                                  size="md"
                                  text="Actions"
                                  variant="outline-secondary"
                              >
                                    <b-dropdown-item @click="onBulkArchive()">
                                        <div class="d-flex align-items-center">
                                            <RtIcon :size="16" variant="light" icon="box-archive"/>
                                            <span class="align-middle ml-50">{{listMode === 'active' ? i18nT(`Archiver la sélection`) : i18nT(`Sortir des archives`)}}</span>
                                        </div>
                                    </b-dropdown-item>
                                    <b-dropdown-item>
                                        <div class="d-flex align-items-center">
                                            <RtIcon :size="16" variant="light" icon="comment-lines"/>
                                            <span class="align-middle ml-50">{{
                                                    i18nT(`Envoyer un message`)
                                                }}</span>
                                        </div>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="onBulkDelete()">
                                        <div class="d-flex align-items-center">
                                            <span class="text-danger"><RtIcon :size="16" variant="light"
                                                icon="trash"/>
                                            </span>
                                            <span
                                                class="align-middle ml-50 text-danger"
                                            >{{
                                                    i18nT(`Supprimer`)
                                                }}</span>
                                        </div>
                                  </b-dropdown-item>
                              </b-dropdown>
                          </div>
                          <!-- search input -->
                          <div class="col-12 col-md-6 col-sm-6 mb-1 pr-0 pl-0 d-flex justify-content-md-end">
                              <div class="custom-search d-flex justify-content-end">
                                  <b-form-group>
                                      <div
                                          class="input-group input-group-merge"
                                          role="group"
                                      >
                                          <div class="input-group-prepend">
                                              <div class="input-group-text">
                                                    <RtIcon variant="light" icon="search"/>
                                              </div>
                                          </div>
                                          <b-form-input
                                              v-model.lazy="searchQuery"
                                              :placeholder="i18nT(`Chercher`)"
                                              class="d-inline-block"
                                              type="text"
                                          />
                                      </div>
                                  </b-form-group>

                              </div>
                          </div>
                      </div>
                      <b-table
                          ref="refTenantsListTable"
                          v-model="currentItems"
                          :key="'TenantsTable'+tableColumns.length"
                          :empty-html="emptyHtml"
                          :fields="computedColumns"
                          :items="fetchTenants"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="isSortDirDesc"
                          class="position-relative"
                          primary-key="id"
                          responsive
                          show-empty
                          @refreshed="onTableRefresh"
                      >
                          <template v-slot:head(checkbox)="data">
                              <span>{{ data.label.toUpperCase() }}</span>
                              <b-form-checkbox
                                  v-model="selectAllStatus"
                                  class="d-inline-block pl-0"
                                  @change="onSelectAll"
                              >
                              </b-form-checkbox>
                          </template>

                        <template #head(action)="data">
                                <span>{{ data.label.toUpperCase() }}</span>
                                <div class="sk-column-control">
                                    <b-dropdown
                                        no-caret
                                        variant="button"
                                        toggle-class="p-0"
                                        :right="true"
                                        class="pr-1 pl-1 d-flex sk-column-filter"
                                        v-if="true"
                                        v-b-tooltip="i18nT(`Colonnes`)"
                                    >
                                        <template #button-content>
                                            <RtIcon variant="light" icon="gear text-body ren-lh-22" size="18px"/>
                                        </template>

                                        <div class="pl-1 pr-1" v-for="(field, index) in availableFields"
                                             :key="'Fields - ' + index">
                                            <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">
                                                {{ i18nT(field.label) }}
                                            </b-form-checkbox>
                                        </div>
                                    </b-dropdown>
                                </div>
                        </template>

                          <template #cell(checkbox)="data">
                              <b-form-checkbox
                                  v-model="selectedItems"
                                  :value="data.item.TenantID"
                                  class="pl-0"
                              />
                          </template>

                          <template #cell(TenantName)="data">
                              <div class="media">
                                  <div class="media-aside align-self-center">
                                      <b-avatar
                                          :src="data.item['image_url']"
                                          :text="getAvatarText(data.item)"
                                          badge-variant="primary"
                                          :style="{
                                      backgroundColor: '#' + (data.item.TenantAvatarHexColor? data.item.TenantAvatarHexColor : data.item.TenantDefaultHexColor),
                                      boxShadow: '0 0 0 2px white, 0 0 0 4px #' + (data.item.TenantAvatarHexColor? data.item.TenantAvatarHexColor : data.item.TenantDefaultHexColor),
                                      color:'white',
                                      }"
                                          :to="{name: 'view-tenant', params: {id: data.item.TenantID}}"
                                      >
                                      </b-avatar>
                                  </div>
                                  <div class="media-body">
                                      <b-link class="mb-0 font-weight-bold d-block text-nowrap ren-link"
                                              v-b-tooltip.hover.topleft.html="tipData(data.item)"
                                              :to="{name: 'view-tenant', params: {id: data.item.TenantID}}"
                                      >
                                          {{ data.item.TenantTitle }} {{ data.item.TenantFullName }}
                                      </b-link>
                                      <p class="m-0">
                                          {{ getTypeName(data.item.TenantType) }}
                                      </p>
                                  </div>
                              </div>
                          </template>

                          <template #cell(TenantType)="data">
                              <p class="m-0">{{ getTypeName(data.item.TenantType) }}</p>
                          </template>

        <!--                      TODO: this should be a link to the property. Get it when the endpoint is up-->

                          <template #cell(PropertyTitle)="data">
                                <div class="d-flex ">
                                    <div v-if="data.item.TenantProperties != null"
                                        v-for="(property, index) in dictToSelectArray(data.item.TenantProperties)"
                                        :key="property.value"
                                        class="mr-1 d-flex ren-property cursor-pointer text-nowrap"
                                    >
                                        <b-link
                                            :to="{name: 'view-property', params: {id: property.value}}"
                                            class="text-nowrap ren-property"
                                        >{{ property.text }}</b-link>
                                    </div>
                                    <b-link v-if="data.item.TenantProperties.length == 0"
                                            variant="primary"
                                            class="btn btn-outline-secondary btn-sm text-nowrap"
                                            :to="{name: 'create-lease', params: {tab: 'general-info'}, query:{tenantId:data.item.TenantID}}"
                                    >
                                        {{ i18nT(`Créer une location`) }}
                                    </b-link>
                                </div>
                          </template>

                        <template #cell(TenantMobilePhone)="data">
                            <b-link
                                :href="data.item.TenantMobilePhone ? 'tel:'+ data.item.TenantMobilePhone : null"
                                class="position-relative pl-1 ren-link text-nowrap"
                            >{{ data.item.TenantMobilePhone ? data.item.TenantMobilePhone : 'N/A'}}</b-link>
                        </template>

                          <template #cell(TenantEmail)="data">
                                <b-link
                                    :href="data.item.TenantEmail ? 'mailto:'+ data.item.TenantEmail : null"
                                    class="position-relative pl-1 ren-link text-nowrap"
                                >{{ data.item.TenantEmail ? data.item.TenantEmail : 'N/A'}}</b-link>
                          </template>

                          <template #cell(TenantBalance)="data">
                              <div class="d-flex align-items-center text-nowrap m-0">
                           <h5 class="m-0"
                               :class="!data.item.TenantBalance? '' : parseFloat(data.item.TenantBalance) < 0 ? 'text-danger' : 'text-success'"
                               v-b-tooltip="i18nT(`Afficher le détail du solde`)"
                           >
                             {{ {amount: data.item.TenantBalance, currency: 'EUR' } | currency }}
                           </h5>
                              <b-badge
                                  v-b-tooltip="data.item.TenantLateRents + ' ' + i18nT(`loyers impayés`)"
                                  class="ml-1"  variant="light-danger"
                                  pill
                                  v-if="data.item.TenantLateRents != 0"
                              >
                                  {{data.item.TenantLateRents}}</b-badge>
                                </div>
                          </template>



                          <template #cell(TenantStatus)="data">
        <!--                          TODO - add click event that send invitation-->
                            <div class="ren-status-badge d-flex">
                              <b-badge
                                  v-b-tooltip = "getTooltipLabel(data.item.StatusCode)"
                                  :variant="statusLabelClass(data.item.StatusCode)"
                                  class="mr-1 badge-pill"
                                  :class="{'cursor-pointer': data.item.StatusCode == 2 }"
                              >
                                {{data.item.StatusText}}
                              </b-badge>
                              <b-badge
                                  v-if="data.item.StatusCode == 3"
                                  variant="light-primary"
                                  class="badge-pill cursor-pointer"
                                  v-b-tooltip = "i18nT(`Envoyer une invitation`)"
                              >
                                {{i18nT(`Réinviter`)}}
                              </b-badge>

                            </div>
                          </template>
                        <template #cell(templates)="data">
                            <b-dropdown
                              toogle-class="p-0"
                              variant="outline-secondary"
                              size="sm"
                            >
                                <template #button-content>
                                    {{i18nT('Modèle')}}
                                </template>

                            <b-dropdown-item v-for="template in data.item['download_models'].slice(1)" :key="template.value" @click="openDownloadModal(template)">
                              <RtIcon variant="light" icon="file-lines mr-1 text-body"/>
                              <span>{{template.text}}</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>

                          <template

                              #cell(action)="data">
                                <b-link :to="{
                                          name: 'edit-tenant',
                                          params: { id: data.item.TenantID },

                                        }"
                                        class='text-secondary'
                                        v-b-tooltip.hover.top="i18nT(`Modifier`)"
                                >
                                        <RtIcon variant="light" icon="edit mr-1" :size="15"/>
                              </b-link>
                              <b-link :to="{name: 'view-tenant', params: {id: data.item.TenantID}}"
                                      class='text-secondary'
                                      v-b-tooltip.hover.top="i18nT(`Voir`)"
                              >
                                    <RtIcon variant="light" icon="eye mr-1" :size="15"/>

                              </b-link>

                              <b-dropdown
                                  no-caret
                                  toggle-class="p-0"
                                  variant="link"
                                  menu-class="action-dropdown"
                                  dropleft
                              >
                                  <template #button-content>
                                      <RtIcon variant="light" icon="ellipsis-vertical align-middle text-body ml-1" :size="18"/>
                                  </template>
                                  <b-dropdown-item
                                      :to="{
                                          name: 'edit-tenant',
                                          params: { id: data.item.TenantID },
                                        }">
                                      <RtIcon variant="light" icon="edit" />
                                      <span class="align-middle ml-50">{{ i18nT(`Modifier`) }}</span>
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                        @click="onArchive(data)"
                                  >
                                        <RtIcon variant="light" icon="box-archive"/>
                                        <span class="align-middle ml-50">
                                            {{listMode === 'active' ? i18nT(`Archiver`) : i18nT(`Désarchiver`)}}
                                        </span>
                                  </b-dropdown-item>
                                  <b-dropdown-item
                                      @click="onDelete(data)">
                                      <RtIcon variant="light" icon="trash text-danger"/>
                                      <span
                                          class="align-middle ml-50 text-danger"
                                      >{{
                                              i18nT(`Supprimer`)
                                          }}</span>
                                  </b-dropdown-item>
                              </b-dropdown>
                          </template>
                      </b-table>
                </div>
                <div class="mx-1 mb-2">
                    <b-row v-if="tenants.length > 0">
                    <b-col
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        cols="12"
                        sm="4"
                    >
                        <b-form-checkbox  v-model="selectAllStatus" class="d-inline-block mx-1"
                            @change="onSelectAll"
                        >
                            {{ i18nT(`Tout`) }}
                        </b-form-checkbox>
                        <b-dropdown
                            variant="outline-secondary"
                            size="sm"
                        >
                            <template #button-content>
                                    <RtIcon variant="light" icon="download"/>
                                    <span class="ml-50">{{ i18nT(`Export`) }}</span>
                            </template>

                            <b-dropdown-item class="text-primary"
                            >
                                <div class="d-flex align-items-center">
                                    <RtIcon variant="light" icon="file-excel"/>
                                    <span class="pl-1">
                                    {{ i18nT(`Export format Excel`) }}
                                        </span>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-item
                            >
                                <div class="d-flex align-items-center">
                                    <RtIcon variant="light" icon="file-lines"/>
                                    <span class="pl-1">{{ i18nT(`Export format OpenOffice`) }}</span>
                                </div>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                    <b-col  cols="12" sm="4" class="d-flex align-items-center justify-content-center">
                        <span class="text-muted ml-1 d-none d-md-flex text-nowrap">
                            {{ i18nT(`Affichage de`) }} {{ dataMeta.from }} {{ i18nT(`à`) }}
                            {{ dataMeta.to }} {{ i18nT(`sur`) }} {{ dataMeta.of }}
                            {{ i18nT(`entrées`) }}</span></b-col>
                    <!-- Pagination -->
                    <b-col
                        class="
                            d-flex
                            align-items-center
                            justify-content-center justify-content-sm-end
                          "
                        cols="12"
                        sm="4"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="totalTenants"
                            class="mb-0 mt-1 mt-sm-0"
                            first-number
                            last-number
                            next-class="next-item"
                            prev-class="prev-item"
                        >
                            <template #prev-text>
                                <RtIcon variant="light" icon="chevron-left" :size="16"/>
                            </template>
                            <template #next-text>
                                <RtIcon variant="light" icon="chevron-right" :size="16"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
                </div>
            </b-card>


<!--        FLOAT ACTIONS-->

        <b-card no-body v-if="selectedItems.length>0" class="float-actions">
            <b-row class="">
                <b-col cols="12"
                       class="d-flex m-1 "
                        >
                    <span class="d-none d-md-flex align-items-center mr-1">
                    {{i18nT(`Actions`)}}
                        </span>
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        v-b-tooltip.hover = "i18nT(`Supprimer la sélection`)"
                        @click="onBulkDelete"
                    >
                        <RtIcon :size="16" variant="light" icon="trash"/>
                    </b-button>
                    <b-button
                        variant="outline-secondary"
                        class="ml-1"
                        size="sm"
                        v-b-tooltip.hover = "listMode === 'active' ? i18nT(`Archiver la sélection`) : i18nT(`Sortir des archives`)"
                        @click="onBulkArchive"
                    >
                        <RtIcon :size="16" variant="light" icon="box-archive"/>
                    </b-button>
                    <b-button
                        variant="outline-secondary"
                        class="ml-1"
                        size="sm"
                        v-b-tooltip.hover = "i18nT(`Envoyer un message`)"
                    >
                        <RtIcon :size="16" variant="light" icon="comment-lines"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

<!--        MODAL DOWNLOAD TEMPLATE-->
        <modal-download-file :downloadFileAction="downloadFileAction"></modal-download-file>
    </div>
</template>

<script>
import {
    VBTooltip,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BButton,
    BButtonGroup,
    BTable,
    BDropdown,
    BDropdownItem,
} from "bootstrap-vue";
import ModalDownloadFile from '../../common/Documents/ModalDownloadFile.vue'
import vSelect from 'vue-select'
import RtSelect from '@core/components/RtSelect.vue'
import useTenantList from "@/views/landlord/Tenants/tableTenantsList";
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from "@core/utils/utils";
import useAuth from '@/auth/useAuth'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RtAllFilters from '@core/components/RtAllFilters.vue'
import store from '@/store/index'


export default {
    components: {
        vSelect,
        BCard,
        BRow,
        BCol,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormRadioGroup,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BButtonGroup,
        ModalDownloadFile,
        RtSelect,
        RtAllFilters
    },
    directives: {
      'b-tooltip': VBTooltip,
        Ripple,
    },
    setup() {
        const {
            fetchTenants,
            tableColumns,
            perPage,
            currentPage,
            totalTenants,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refTenantsListTable,
            department,
            group,
            employees,
            tenantType,
            tenants,
            listMode,
            tenantLandlord,
            tenantProperty,
            activeTenantsCount,
            archiveTenantsCount,
            refetchData
        } = useTenantList(false);

        return {
            fetchTenants,
            tableColumns,
            perPage,
            currentPage,
            totalTenants,
            dataMeta,
            tenantLandlord,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            tenantProperty,
            refTenantsListTable,
            department,
            group,
            listMode,
            avatarText,
            tenantType,
            tenants,
            activeTenantsCount,
            archiveTenantsCount,
            refetchData
        }
    },
    data(){
        return {
            activeButton: 'active',
            tenantLandlords:[],
            selectedProperties: [],
            selectedLandlords: [],
            tenantProperties:[],
            selectedItems: [],
            currentItems: [],
            tenantTypes: [],
            selectAllStatus: false,
            landlordId: useAuth.getLandlordId(),
            templates: [],
            availableFields: [
                {label: this.i18nT(`Type`), key: 'TenantType', visible: true},
                {label: this.i18nT(`Bien`), key: 'PropertyTitle', visible: true},
                {label: this.i18nT(`Téléphone`), key: 'TenantMobilePhone', visible: true},
                {label: this.i18nT(`Email`), key: 'TenantEmail', visible: true},
                {label: this.i18nT(`Solde`), key: 'TenantBalance', visible: true},
                {label: this.i18nT(`Etat`), key: 'TenantStatus', visible: true},
                {label: this.i18nT(`Modèles`), key: 'templates', visible: true},
            ],
            downloadFileAction: [],

        }
    },
    created(){

        let storePreferences = useAuth.getColumnPreferences('tenants')
        if(storePreferences) {
          this.availableFields = storePreferences
        }

        this.getFilters()



        // this.fetchTenants(null, this.propertiesToArray);
    },
    // updated() {
    //     if(this.$refs.templateSelectedProp) {
    //         const element = this.$refs.templateSelectedProp.parentElement
    //         // element.$el.parentNode.removeChild(element.$el);
    //         // console.log(element)
    //         element.parentNode.removeChild(element);
    //     }
    // },
    computed: {
        emptyHtml() {
            let imgPath = store.state.appConfig.layout.skin === 'dark'

            return "<img src='" + require(imgPath ? '@client/images/errorDark.svg' : '@client/images/error.svg')
                + "' alt='Error page' class='img-fluid' width='300'><br/><h2>"
                + this.i18nT(`Il n'y a rien par ici ...`) + "</h2><p>"
                + this.i18nT(`Cette page permet de gérer les locataires. Vous pouvez les inviter à s'inscrire au site et avoir accès à leur zone membres dédiée.`) + "</p>"
                +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/" +"landlord/tenants/new/general-info\"'>" + this.i18nT(`Créer un locataire`) + "</button>"
        },
        filterConditions() {
            return this.tenantType || this.selectedLandlords.length > 0 || this.selectedProperties.length > 0
        },
        computedColumns() {

          let col = [...this.tableColumns]

          for(let i = 0; i < this.availableFields.length; i++) {
            if(!this.availableFields[i].visible) {
              col = col.filter(c => c.key !== this.availableFields[i].key)
            }
          }
          useAuth.setColumnPreferences('tenants', this.availableFields);

          return col;
        },
    },
    watch: {
        tenantLandlord: {
            handler() {
                this.getFilters()
            },
            deep: true
        }
    },
    methods: {
        getFilters() {

            const landlordFilters = this.tenantLandlord.length > 0 ? "?LandlordID=" + this.tenantLandlord.join(',') : ""

            this.$http.get(`landlord/tenants/filters${landlordFilters}`).then(({data}) => {

                this.tenantLandlords = dictToSelectArray(data.data.landlords);
                this.tenantLandlords.unshift({
                    value: '-1',
                    text: this.i18nT(`Tout`)
                })

                this.tenantTypes = dictToSelectArray(data.data.types);
                this.tenantTypes.unshift({
                    value: '-1',
                    text: this.i18nT(`Tout`)
                });

                const properties = dictToSelectArray(data.data.properties);
                const buildings = dictToSelectArray(data.data.buildings);

                properties.unshift({
                    value: 0,
                    text: this.i18nT(  `-- BIENS --`),
                    disabled: true,
                });

                buildings.unshift({
                    value: 0,
                    text: this.i18nT(  `-- IMMEUBLES --`),
                    disabled: true
                });

                this.tenantProperties = [...properties, ...buildings];

                this.tenantProperties.map(property=> ({...property, disabled: property.disabled ? true : false}))
                this.tenantProperties.unshift({
                    value: '-1',
                    text: this.i18nT(`Tout`)
                });

            });
        },
        clearFilters() {
            this.tenantType = ''
            this.tenantProperty = [];
            this.tenantLandlord = [];
        },
        removeFilter(filter) {
            const multiple = typeof(this[filter.originalArray]) == 'object'
            
            if(!multiple) {
                this[filter.originalArray] = ''
            } else {
                this[filter.originalArray] = this[filter.originalArray].filter(item => item !== filter.value)
            }
        },
        isSelectedOptionVisible(arr, item) {
            return arr.indexOf(item) === 0
        },
        getAvatarText(tenant) {
            if(tenant.TenantFirstName && tenant.TenantLastName) {
            return `${tenant.TenantFirstName.charAt(0)}${tenant.TenantLastName.charAt(0)}`
            } else if(tenant.TenantCompanyName){
            return `${tenant.TenantCompanyName.charAt(0)}${tenant.TenantCompanyName.charAt(1)}`
            } else {
            return ''
            }
        },
        dictToSelectArray,
        openDownloadModal(file) {
            this.downloadFileAction = file.links
            this.$bvModal.show('modal-download-file')
          },
        tipData(tenant){

        const status = tenant.StatusText;
        const statusClass = this.statusLabelClass(tenant.StatusCode);
        const typeName = this.getTypeName(tenant.TenantType);
        const profession = tenant.TenantProfession;
        const revenus = tenant.TenantRevenus;


        const statusTitle = this.i18nT(`Etat`);
        const typeTitle = this.i18nT(`Type`);
        const professionTitle = this.i18nT(`Profession locataire`);
        const revenusTitle = this.i18nT(`Revenus`);



        return `
            <div class="tooltip-container">
              <div class="tooltip-text-container">
                ${typeName ?`<p class="tooltip-text"><b>${typeTitle}:</b>&nbsp;&nbsp;${typeName}</p>` : ''}
                ${profession ? `<p class="tooltip-text"><b>${professionTitle}:</b>&nbsp;&nbsp;${profession}</p>` : ''}
                ${revenus ? `<p class="tooltip-text"><b>${revenusTitle}:</b>&nbsp;&nbsp;${revenus}</p>` : ''}
                ${status ? `<p class="tooltip-text"><b>${statusTitle}:</b>&nbsp;&nbsp; <span class="tooltip-badge bg-${statusClass}">${status}</span></p>` : ''}
              </div>
            </div>`;
        },
        getTooltipLabel(statusCode) {

            const statusCodesDic = {
                 1: this.i18nT(`Le locataire n'a pas d'email`),
                 2: this.i18nT(`Envoyer une invitation`),
                 3: this.i18nT(`Le locataire n'a pas encore accepté l'invitation`),
                 4: this.i18nT(`Le locataire a créé son compte`)
        };

           return statusCodesDic[statusCode]
        },
        statusLabelClass(statusCode) {

            const statusClassDic = {
                1: 'light-info',
                2: 'light-primary',
                3: 'light-warning',
                4: 'light-secondary'
            }

            return statusClassDic[statusCode]
        },
        onSelectAll(val) {
          if (val) {
              this.selectedItems = this.currentItems.map(item => item.TenantID)
          } else {
              this.selectedItems = []
          }
        },
        onTableRefresh() {
          this.selectedItems = []
        },

        onDelete(tenant) {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression du locataire.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    console.log(result.value)
                    this.$http
                        .delete(`landlord/tenants?id=${tenant.item.TenantID}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès!`),
                                text: this.i18nT(`Supprimé avec succès!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.refetchData()
                        }).catch(err => {
                        this.$swal({
                            icon: 'error',
                            title: 'Error!',
                            text: this.errorMessage(err),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onArchive(tenant) {

            const type = this.listMode === 'active' ? 'archive' : 'unarchive'

            this.$http.post(`landlord/tenants/${type}?id=${tenant.item.TenantID}`)
                .then(() => {
                    this.refetchData()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        getTypeName(type) {
          if (type == 'person') {
              return this.i18nT(`Particulier`);
          } else if (type == 'company') {
              return this.i18nT(`Société`);
          }
          return '';
        },
        onBulkArchive() {

          const type = this.listMode === 'active' ? 'massArchive' : 'massUnarchive'

          this.$http.post(`landlord/tenants/${type}?ids=${this.selectedItems.join(',')}`)
            .then(() => {
                this.selectedItems = []
                this.selectAllStatus = false
                this.refetchData()
            })
            .catch(err => {
                this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: this.errorMessage(err),
                    icon: 'InfoIcon',
                    variant: 'danger'
                }
                })
            })
        },
        onBulkDelete() {
            console.log(this.selectedItems.join(','))
          this.$swal({
              title: this.i18nT(`Avertissement`),
              text: this.i18nT(`Veuillez confirmer.`),
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: this.i18nT(`Annuler`),
              confirmButtonText: this.i18nT(`Confirmer`),
              customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1'
              },
              buttonsStyling: false
          }).then(result => {
              if (result.value) {
                console.log(result.value)
                  this.$http
                      .delete(`landlord/tenants/massDelete?ids=${this.selectedItems.join(',')}`)
                      .then(() => {
                          this.$swal({
                              icon: 'success',
                              title: this.i18nT(`Succès!`),
                              text: this.i18nT(`Supprimé avec succès!`),
                              customClass: {
                                  confirmButton: 'btn btn-success'
                              }
                          })
                          this.selectedItems = []
                          this.selectAllStatus = false
                          this.refetchData()
                      }).catch(err => {
                      this.$swal({
                          icon: 'error',
                          title: 'Error!',
                          text: this.errorMessage(err),
                          customClass: {
                              confirmButton: 'btn btn-success'
                          }
                      })
                      this.selectedItems = []
                      this.selectAllStatus = false
                      this.refetchData()
                  })
              }
          })
        },
    },
}
    </script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    .ren-status-badge {
      max-width: 150px;
      min-width: 120px
    }


    //change button text color in dark mode
    .dark-layout .btn-group-toggle label[class*=btn-outline-] {
        color: #d6d5d0 !important;
    }



    .sk-column-filter{
      width: 50px;
    }

    .btn-new-tenant {
      align-items: center;
      height: 38px;
      margin-left: 10px;
    }

    .ren-link[href="#"] {
        color: inherit; /* inherit color from parent */
    }

    .ren-link[href="#"]:hover {
        color: inherit !important; /* Keeps the color same on hover */
        cursor: default !important;
    }

    .ren-filter .vs__selected {
        display: none;
    }

    .ren-filter-badge {
        padding: 10px;
        margin: 5px;
    }



    //.table-responsive:has(.dropdown.show) {
    //        overflow: visible !important;
    //        z-index: 11 !important;
    //}


    </style>
