import { ref, watch, computed } from '@vue/composition-api'
import router from '@/router'
import axios from '@axios'
import store from '@/store'
import {useUtils as useI18nUtils} from "@core/libs/i18n";
const { i18n } = useI18nUtils();
function i18nT(str){
	return i18n(str)
};

export default function useTenantList(isTemplate) {

	// let userData = JSON.parse(localStorage.getItem('userData'));
	// console.log("Landlord ID: ", userData.LandlordID);


	const refTenantsListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		{ key: 'checkbox', label: '', thClass: 'tbl-chk', tdClass: 'tbl-chk' },
		{ key: 'TenantName', label: i18nT(`Locataire`), sortable: true, thClass: 'pl-1', tdClass: 'pl-1' },
		{ key: 'TenantType', label: i18nT(`Type`), sortable: true },
		{ key: 'PropertyTitle', label: i18nT(`Bien`), sortable: true },
		{ key: 'TenantMobilePhone', label: i18nT(`Téléphone`), sortable: true },
		{ key: 'TenantEmail', label: i18nT(`Email`), sortable: true },
		{ key: 'TenantBalance', label: i18nT(`Solde`), sortable: true },
		{ key: 'TenantStatus', label: i18nT(`Etat`), sortable: true },
		{ key: 'templates',  label: i18nT(`Modèles`), sortable: false },
		{
			key: 'action',
			label: i18nT(`Actions`),
			thClass: 'tbl-actions',
			tdClass: 'sticky-column',
			stickyColumn: true,
		},
	]
	const perPage = ref(10)
	const totalTenants = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('')
	const isSortDirDesc = ref(true)
	const tenants = ref([])
	const tenantType = ref('')
	const tenantProperty = ref([])
	const tenantLandlord = ref([])
	const listMode = ref('active')
	const activeTenantsCount = ref(0);
	const archiveTenantsCount = ref(0);


	// Checking for params in router

	const queries = router.currentRoute.query


	if(queries.currentPage) {
		currentPage.value = queries.currentPage
	}

	if(queries.tenantType) {
		tenantType.value = queries.tenantType
	}

	if(queries.tenantProperty) {
		tenantProperty.value = queries.tenantProperty.split(',')
	}

	if(queries.tenantLandlord) {
		tenantLandlord.value = queries.tenantLandlord.split(',')
	}

	if(queries.archive){
		listMode.value = 'archive';
	}



	const dataMeta = computed(() => {
		const localItemsCount = refTenantsListTable.value
			? refTenantsListTable.value.localItems.length
			: 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalTenants.value,
		}
	})

	let timeoutId = null

	const refetchData = () => {

		const query = {}

		if( currentPage.value !== 1) {
			query.currentPage = currentPage.value
		}
		if (tenantType.value !== '' && tenantType.value != '-1') {
			query.tenantType = tenantType.value
		}
		if(tenantProperty.value.length > 0 && !(tenantProperty.value.includes('-1'))) {
			query.tenantProperty = tenantProperty.value.join(',')
		}
		if(tenantLandlord.value.length > 0 && !(tenantLandlord.value.includes('-1'))) {
			query.tenantLandlord = tenantLandlord.value.join(',')
		}
		if(listMode.value == 'archive') {
			query.archive = '1';
		}

		// router.push({query: query})

		const queryString = new URLSearchParams(query).toString();
		if(queryString !== ''){
			history.pushState({}, '', '?' + queryString);
		} else{
			history.pushState({}, '', location.pathname);
			}
		refTenantsListTable.value.refresh()
	}

	watch(
		[
			currentPage,
			perPage,
			tenantProperty,
			tenantType,
			tenantLandlord,
			listMode,
		],
		() => {
			refetchData()
		}
	)

	watch(
		searchQuery,
		() => {
			clearTimeout(timeoutId)
			timeoutId = setTimeout(() => {
				refetchData()
			}, 500)
		}
	)

	const fetchTenants = (ctx, callback) => {
		const params = {
			page: currentPage.value,
			show_per_page: perPage.value,
			sort_by: sortBy.value,
			sort_order: isSortDirDesc.value ? 'DESC' : 'ASC',
			search: searchQuery.value
		}

		if (tenantType.value !== '' && tenantType.value != '-1') {
			params['filter[TenantType]'] = tenantType.value
		}
		if(tenantProperty.value.length > 0 && !(tenantProperty.value.includes('-1'))) {
			params['filter[PropertyID]'] = tenantProperty.value.join(',')
		}
		if(tenantLandlord.value.length > 0 && !(tenantLandlord.value.includes('-1'))) {
			params['filter[LandlordID]'] = tenantLandlord.value.join(',')
		}
		if(listMode.value == 'active') {
			params['active'] = 1;
		}


		axios
			.get(`landlord/tenants`, {
				params,
			})
			.then(({ data }) => {
				if (data.data.tenants) {
					tenants.value = data.data.tenants
					callback(data.data.tenants)
				} else {
					tenants.value = []
					callback([])
				}

				totalTenants.value = data.data.pagination.records_count
				activeTenantsCount.value = data.data['records_count_active'];
				archiveTenantsCount.value = data.data['records_count_archive'];

			}).catch(err => {
				store.commit('app/SET_GLOBAL_MESSAGE', {message: err.response.data['error_message'], type:'danger'} )
		})
	}

	return {
		fetchTenants,
		tableColumns,
		perPage,
		currentPage,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refTenantsListTable,
		totalTenants,
		tenantType,
		tenantProperty,
		tenantLandlord,
		listMode,
		tenants,
		activeTenantsCount,
		archiveTenantsCount,
		refetchData,
	}
}
